import React from "react";
import _ from "lodash";

import { doHttpRequest } from "../../services/doHttpRequest";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { IStore } from "../../store/store";
import { useSelector, useDispatch } from "react-redux";
import { updateActions } from "../../store/updateStore";
import { useSearchFunctions } from "../common/useSearchFunctions";
import { useForm, FormProvider } from "react-hook-form";

import { FormFields } from "./FormFields";

import { Button } from "@fluentui/react-components";

import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
} from "@fluentui/react-components";

import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

const useStyles = makeStyles({
  modal: {
    top: "0px",
    height: "100vh",
    maxHeight: "100vh",
    maxWidth: "100vw",
    transform: "translate(0)",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    //media
    left: "40px",
    width: "calc(100vw - 40px)",
    ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),

    "@media screen and (min-width: 576px)": {
      left: "100px",
      width: "calc(100vw - 100px)",
      ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalL),
    },
    "@media screen and (min-width: 768px)": {
      left: "250px",
      width: "calc(100vw - 250px)",
    },
    "@media screen and (min-width: 992px)": {
      left: "50vw",
      width: "50vw",
    },
  },

  content: {
    height: "100%",
    maxHeight: "100%",
    ...shorthands.overflow("hidden", "auto"),
  },
});

export const DialogContainer: React.FunctionComponent<{}> = () => {
  const styles = useStyles();

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { getQueryKeyObject } = useSearchFunctions();
  /**
   * Parameter, welcher Datensatz upgedatet werden soll
   */
  const {
    editPanelIsVisible,
    saveButtonEnabled,
    savedSuccessfully,
    primaryId,
    caption,
    readResourceEntity,
    updateResourceEntity,
    dataFields,
    reactQueryString,
    searchFields,
  } = useSelector((state: IStore) => state.update);

  const methods = useForm<any>();
  const {
    handleSubmit,
    setError,
    formState: { isDirty },
  } = methods;

  const hideEditPanelHandler = (dataOpen: boolean) => {
    if (savedSuccessfully) {
      dispatch(updateActions.hideEditPanel());
      return;
    }
    if (isDirty) {
      //Abfrage, ob wirklich geschlossen werden soll ohne zu speichern
      dispatch(updateActions.showConfirmPanel());
    } else {
      dispatch(updateActions.hideEditPanel());
    }
  };

  const mutation = useMutation({
    mutationFn: ({ url, formData }: { url: string; formData: string }) => {
      return doHttpRequest(url, "patch", formData);
    },
    onSuccess: (data: any) => {
      if (data.status < 300) {
        /**
         * remove update query
         */
        queryClient.removeQueries({
          queryKey: _.concat(reactQueryString, "entity", primaryId),
          exact: true,
        });

        /**
         * Update collection query
         */
        queryClient.refetchQueries({
          queryKey: _.concat(reactQueryString, getQueryKeyObject(searchFields)),
          exact: true,
        });

        /**
         * close UpdateContainer and show success message
         */
        methods.reset();
        dispatch(updateActions.setSavedSuccessfully(true));
        dispatch(updateActions.hideEditPanel());
      } else {
        /**
         * Update war nicht erfolgreich --> Fehler anzeigen
         */
        if (!_.isObject(data.data.validation_messages)) {
          setError("id", {
            message: "Fehlermeldung... Es wurde nichts gespeichert.",
          });
        } else {
          _.mapKeys(data.data.validation_messages, function (value: any) {
            setError(value.field, {
              message: value.message,
            });
          });
        }
        dispatch(updateActions.enableSaveButton());
      }
    },
  });

  /**
   *
   */
  function onSubmit(formData: any, event: React.FormEvent | any) {
    event.preventDefault();

    dispatch(updateActions.disableSaveButton());

    //contact server with data
    const getJsonString = (formData: any) => {
      const jsonToStringify: any = {};
      /** Beispiel
       * JSON.stringify({
       *   partner_id: formData.partner_id,
       *   name: formData.name,
       * });
       */
      dataFields.map((field: any) => {
        jsonToStringify[field.name] = formData[field.name];
      });
      return jsonToStringify;
    };

    return mutation.mutate({
      url: updateResourceEntity + primaryId,
      formData: getJsonString(formData),
    });
  }

  const onError = (errors: any, event: React.FormEvent | any) => {
    event.preventDefault();

    setError("id", {
      message: "Fehlermeldung... Es wurde nichts gespeichert.",
    });
  };

  return (
    <Dialog
      open={editPanelIsVisible}
      modalType="alert"
      onOpenChange={(event, data) => hideEditPanelHandler(data.open)}
    >
      <FormProvider {...methods}>
        <DialogSurface aria-label="label" className={styles.modal}>
          <DialogBody id="idDialogBody" className={styles.content}>
            <DialogTitle>{caption}</DialogTitle>
            <DialogContent>
              <FormFields
                primaryId={primaryId}
                readResourceEntity={readResourceEntity}
                dataFields={dataFields}
                reactQueryString={reactQueryString}
              />
            </DialogContent>
            <DialogActions>
              <DialogTrigger>
                <Button
                  appearance="secondary"
                  // disabled={!saveButtonEnabled}
                  onClick={() => {
                    methods.reset();
                    dispatch(updateActions.hideEditPanel());
                  }}
                >
                  Abbrechen
                </Button>
              </DialogTrigger>
              <Button
                type="submit"
                onClick={handleSubmit(onSubmit, onError)}
                appearance="primary"
                disabled={!isDirty || !saveButtonEnabled}
              >
                Speichern
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </FormProvider>
    </Dialog>
  );
};
