import React from "react";
import { Button } from "@fluentui/react-components";

import {
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  pageItemHeader: {
    maxHeight: "32px",
    ...shorthands.flex(1, 1, "auto"),
    ...shorthands.padding(tokens.spacingVerticalS),
    ...shorthands.borderTop(
      tokens.strokeWidthThick,
      "solid",
      tokens.colorBrandBackground
    ),
    ...shorthands.borderBottom(
      tokens.strokeWidthThin,
      "solid",
      tokens.colorBrandBackground
    ),
  },
  headerContainer: {
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    alignContent: "flex-start",
  },
  headerItemLeft: {
    ...shorthands.flex(0, 1, "auto"),
    ...shorthands.padding("0", tokens.spacingHorizontalL, "0", "0"),
    alignSelf: "flex-start",
  },
  pageContainer: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "column",
    ...shorthands.overflow("hidden"),
  },

  pageItemContent: {
    height: "100%",
    ...shorthands.overflow("auto", "hidden"),
    ...shorthands.flex(1, 1, "auto"),
    ...shorthands.padding(tokens.spacingVerticalS),
  },
});

export const Error: React.FunctionComponent<any> = () => {
  const styles = useStyles();

  return (
    <React.Fragment>
      <div className={styles.pageContainer}>
        <div
          className={mergeClasses(
            styles.pageItemHeader,
            styles.headerContainer
          )}
        >
          <div className={styles.headerItemLeft}>Peritos Gmbh - Fehler</div>
        </div>
        <div className={styles.pageItemContent}>
          Entschuldigung, ein unerwarteter Fehler ist aufgetreten. Bitte laden
          Sie die Seite erneut.
          <br />
          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            Seite neu laden
          </Button>
          <Button
            onClick={() => {
              window.location.href = "/home";
            }}
          >
            zur Startseite
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};
