import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { IStore } from "./store";

const initialSettingState: IStore["setting"] = {
  design: "browser",
  designIsDark: false,
  language: "de_DE",
  currency: "EUR",
  timezone: "Europe/Berlin",
};

const settingSlice = createSlice({
  name: "setting",
  initialState: initialSettingState,
  reducers: {
    reset(state) {
      _.assign(state, initialSettingState);
    },
    setDesign(state, action) {
      state.designIsDark = action.payload === "dark";
    },

    setLanguage(state, action) {
      if (action.payload === "") {
        if (state.language === "de_DE") {
          state.language = "en_GB";
        } else {
          state.language = "de_DE";
        }
      } else {
        state.language = action.payload;
      }
    },
  },
});

export const settingActions = settingSlice.actions;

export default settingSlice.reducer;
