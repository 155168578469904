import React from "react";

import "@dotlottie/player-component";

import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  lottie: {
    width: "100%",
    maxWidth: "400px",
  },
});

export const Lottie: React.FunctionComponent<{ lottie_name: string }> = ({
  lottie_name,
}) => {
  const styles = useStyles();

  return (
    <div className={styles.lottie}>
      <dotlottie-player
        src={"lotties/" + lottie_name + ".lottie"}
        autoplay
        loop
      />
    </div>
  );
};
