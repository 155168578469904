import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { IStore } from "./store";

const initialAuthState: IStore["auth"] = {
  userId: 11111111,
  isAuthenticated: false,
  accessToken: "",
  isMicrosoft: false,
  fullName: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    reset(state) {
      _.assign(state, initialAuthState);
    },
    setToken(state, action) {
      state.accessToken = action.payload;
    },
    login(state, action) {
      state.userId = action.payload.userId;
      state.isAuthenticated = true;
      state.accessToken = action.payload.accessToken;
      state.isMicrosoft = action.payload.isMicrosoft;
      state.fullName = action.payload.fullName;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
