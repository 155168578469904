import React, { useEffect } from "react";
import _ from "lodash";

import { IDataDefinition } from "../common/IDataDefinition";
import { IStore } from "../../store/store";

import { useDispatch, useSelector } from "react-redux";
import { searchActions } from "../../store/searchStore";

import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useReactQueryAxios } from "../../services/useReactQueryAxios";
import { useInView } from "react-intersection-observer";

import { useSearchFunctions } from "./useSearchFunctions";

import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Text, Button, Spinner } from "@fluentui/react-components";
import { Alert } from "@fluentui/react-components/unstable";

import { DataCollectionEntity } from "./DataCollectionEntity";

import { Lottie } from "../../lotties/Lottie";

import {
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { JsxElement } from "typescript";
import { DelayedSpinner } from "../editDialog/DelayedSpinner";

const useStyles = makeStyles({
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    // ...shorthands.overflow("auto"),
    ...shorthands.padding(tokens.spacingHorizontalS),
    ...shorthands.gap(tokens.spacingHorizontalS),
    backgroundColor: tokens.colorNeutralBackground4,
  },

  card: {
    ...shorthands.flex(1, 1, "60px"),
    boxShadow: tokens.shadow2,
    ...shorthands.borderRadius(tokens.borderRadiusLarge),
    ...shorthands.padding(tokens.spacingHorizontalS),
    backgroundColor: tokens.colorNeutralBackground1,
    ":hover": {
      ...shorthands.borderColor(tokens.colorBrandBackground),
      ...shorthands.borderStyle("solid"),
      cursor: "pointer",
    },
  },
});

export const DataCollectionBody: React.FunctionComponent<{
  dataDefinition: IDataDefinition;
}> = ({ dataDefinition }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { rpcFetchAll } = useReactQueryAxios();
  const { getApiQueryString, getQueryKeyObject } = useSearchFunctions();

  const { ref, inView } = useInView();

  const {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: _.concat(
      dataDefinition.reactQueryString,
      getQueryKeyObject(dataDefinition.searchFields)
    ),
    queryFn: async ({ pageParam = 1 }) => {
      return await rpcFetchAll(getApiQueryString(dataDefinition, pageParam));
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length === 0 || lastPage.length < dataDefinition.pageCount)
        return undefined;

      return pages.length + 1;
    },
  });

  React.useEffect(() => {
    try {
      let anzahl = 0;
      if (data && !_.isEmpty(data.pages[0]) && !_.isEmpty(data.pages[0][0])) {
        anzahl = data.pages[0][0].anzahl;
      }
      if (status === "success" && !isFetching) {
        dispatch(
          searchActions.setCount({
            reactQueryString: dataDefinition.reactQueryString,
            anzahl: anzahl,
          })
        );
      }
    } catch {}
  }, [data, status, isFetching]);

  React.useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <div className={styles.flexWrapper}>
      {status === "loading" ? (
        <DelayedSpinner delayTime={500} />
      ) : status === "error" ? (
        <Alert intent="error" action="Retry">
          Fehler beim Laden der Daten
        </Alert>
      ) : (
        <React.Fragment>
          {!_.isEmpty(data.pages) &&
          _.isArray(data.pages) &&
          !_.isEmpty(data.pages[0]) &&
          !_.isEmpty(data.pages[0][0]) ? (
            data.pages.map((page, index) => (
              <React.Fragment key={index}>
                {page.map((data: any) => (
                  <DataCollectionEntity
                    dataDefinition={dataDefinition}
                    data={data}
                    key={data.id}
                  />
                ))}
              </React.Fragment>
            ))
          ) : (
            <div>
              <Lottie lottie_name="not-found-man-files" />
              <Text>Ihre Suchanfrage liefert keine Ergebnisse.</Text>
            </div>
          )}
          <div>
            <Button
              ref={ref}
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage || isFetchingNextPage}
              appearance="transparent"
            >
              {isFetchingNextPage ? (
                <Spinner label="weitere Daten werden geladen..." />
              ) : hasNextPage ? (
                <Spinner label="Daten werden aktualisiert..." />
              ) : null}
            </Button>
          </div>
          <div>
            {isFetching && !isFetchingNextPage ? (
              <Spinner label="Daten werden im Hintergrund aktualisiert..." />
            ) : null}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
