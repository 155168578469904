import React from "react";
import _ from "lodash";

import { IDataDefinition } from "../common/IDataDefinition";
import { IStore } from "../../store/store";

import { useSearchFunctions } from "./useSearchFunctions";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useSearchParams } from "react-router-dom";

import { Text } from "@fluentui/react-components";

import { MainTextSearchField } from "./MainTextSearchField";

import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

const useStyles = makeStyles({
  menu: {
    backgroundColor: tokens.colorNeutralBackground1,
  },
  menuButton: {
    alignSelf: "center",
  },
  items: {
    ...shorthands.padding(
      tokens.spacingVerticalM,
      tokens.spacingHorizontalMNudge
    ),
  },

  componentContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    ...shorthands.gap(tokens.spacingHorizontalS),
  },

  componentLeft: {
    minWidth: "160px",
    ...shorthands.flex(1, 1, "220px"),
    ...shorthands.padding(
      tokens.spacingVerticalM,
      "0",
      "0",
      tokens.spacingVerticalMNudge
    ),
  },

  componentRight: {
    ...shorthands.flex(1, 1, "140px"),
    ...shorthands.padding(
      tokens.spacingVerticalMNudge,
      tokens.spacingVerticalXL,
      "0",
      "0"
    ),
    // alignSelf: "flex-end",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },

  searchField: {
    maxWidth: "128px",
  },
});

export const DataCollectionHeader: React.FunctionComponent<{
  dataDefinition: IDataDefinition;
}> = ({ dataDefinition }) => {
  /**
   * interner Cache
   ************************************************************************************
   */
  const dispatch = useDispatch();

  const urlSuchParameter = useSelector(
    (state: IStore) => state.search.urlSuchParameter
  );

  const searchCount = useSelector(
    (state: IStore) =>
      state.search.apiAbfragen[dataDefinition.reactQueryString.join(",")]
  );

  /**
   * externer Cache + API-Aufruf
   ************************************************************************************
   */

  /**
   * Routing
   ************************************************************************************
   */
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { debouncedSearch } = useSearchFunctions();

  /**
   * Side effects
   ************************************************************************************
   */

  /**
   * AdvancedSearch
   */
  const [target, setTarget] = React.useState<HTMLElement | null>(null);

  /**
   * component logic
   ************************************************************************************
   */

  /**
   * Popover
   */

  /**
   * styles
   ************************************************************************************
   */
  const styles = useStyles();

  /**
   * return
   ************************************************************************************
   * @todo overflow can be found in HeaderSchadenereignis Sicherung vom 29.11.2022
   */
  return (
    <div className={styles.componentContainer}>
      <div className={styles.componentLeft}>
        {dataDefinition.icon}
        <Text weight="semibold">
          {dataDefinition.captionCollection} (
          {_.isUndefined(searchCount) ? "0" : searchCount})
        </Text>
      </div>
      <div className={styles.componentRight}>
        <MainTextSearchField dataDefinition={dataDefinition} />
      </div>
    </div>
  );
};
