import React from "react";

import { IDataDefinition } from "../common/IDataDefinition";
import { DataCollection } from "../common/DataCollection";

import { MailRegular as IconMail } from "@fluentui/react-icons";
import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

const mailDataDefinition: IDataDefinition = {
  reactQueryString: ["mail"],
  databaseSuperclass: "",
  pageCount: 50,
  icon: <IconMail color={tokens.colorBrandForegroundOnLight} />,
  captionCollection: "E-Mails",
  captionEntity: "E-Mail",
  readResourceCollection: "/mail",
  readResourceEntity: "/mail/",
  updateResourceEntity: "/mail/",
  dataFields: [
    {
      art: "input",
      type: "text",
      name: "subject",
      label: "Betreff",
      hint: "64 Zeichen",
    },
    {
      art: "input",
      type: "text",
      name: "from.emailAddress",
      label: "Kommentar",
    },
  ],
  searchFields: [
    {
      art: "input",
      type: "text",
      urlParameter: "sb",
      label: "Betreff",
      databaseTable: "mail",
      databaseField: "subject",
      whereFilterType: "like",
      hint: "64 Zeichen",
    },
    {
      art: "input",
      type: "text",
      urlParameter: "sk",
      label: "Kommentar",
      databaseTable: "schaden",
      databaseField: "kommentar",
      whereFilterType: "like",
    },
  ],
};

const useStyles = makeStyles({
  componentContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    ...shorthands.gap(tokens.spacingHorizontalS),
  },
});

export const MailCollection: React.FunctionComponent<any> = () => {
  const styles = useStyles();

  return (
    <div className={styles.componentContainer}>
      <DataCollection dataDefinition={mailDataDefinition} />
    </div>
  );
};

export default MailCollection;
