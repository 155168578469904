import React from "react";
import _ from "lodash";

import { IDataDefinition } from "./IDataDefinition";

import { useDispatch } from "react-redux";
import { updateActions } from "../../store/updateStore";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { doHttpRequest } from "../../services/doHttpRequest";

import {
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  flexWrapper: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    ...shorthands.padding(tokens.spacingHorizontalS),
    ...shorthands.gap(tokens.spacingHorizontalS),
    backgroundColor: tokens.colorNeutralBackground4,
  },

  card: {
    ...shorthands.flex(1, 1, "60px"),
    boxShadow: tokens.shadow2,
    ...shorthands.borderRadius(tokens.borderRadiusLarge),
    ...shorthands.padding(tokens.spacingHorizontalS),
    backgroundColor: tokens.colorNeutralBackground1,
    ":hover": {
      ...shorthands.borderColor(tokens.colorBrandBackground),
      ...shorthands.borderStyle("solid"),
      cursor: "pointer",
    },
  },
});

export const DataCollectionEntity: React.FunctionComponent<{
  dataDefinition: IDataDefinition;
  data: any;
}> = ({ dataDefinition, data }) => {
  /**
   * interner Cache
   ************************************************************************************
   */
  const dispatch = useDispatch();

  /**
   * externer Cache + API-Aufruf
   ************************************************************************************
   */
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (id: string) => {
      return doHttpRequest(
        "/mail/import/" +
          localStorage.getItem("ddId") +
          "/" +
          "kontext" +
          "/" +
          id
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["mail"] });
    },
  });
  /**
   * Routing
   ************************************************************************************
   */

  /**
   * Side effects
   ************************************************************************************
   */

  /**
   * component logic
   ************************************************************************************
   */

  const onClickOpenUpdateDialog = (event: React.MouseEvent<HTMLDivElement>) => {
    dispatch(
      updateActions.showEditPanel({
        primaryId: event.currentTarget.id,
        caption: dataDefinition.captionEntity,
        readResourceEntity: dataDefinition.readResourceEntity,
        updateResourceEntity: dataDefinition.updateResourceEntity,
        dataFields: dataDefinition.dataFields,
        reactQueryString: dataDefinition.reactQueryString,
        searchFields: dataDefinition.searchFields,
      })
    );
  };

  const onDragStart = (
    event: React.MouseEvent<HTMLDivElement>,
    databaseSuperclass: string
  ) => {
    console.log("onDragStart" + event.currentTarget.id);
    localStorage.setItem("ddDatabaseSuperclass", databaseSuperclass);
    localStorage.setItem("ddId", event.currentTarget.id);
  };
  const onDragEnter = (
    event: React.MouseEvent<HTMLDivElement>,
    bezeichnung: string
  ) => {
    console.log("onDragEnter" + event.currentTarget.id + bezeichnung);
  };
  const onDragLeave = (
    event: React.MouseEvent<HTMLDivElement>,
    bezeichnung: string
  ) => {
    console.log("onDragLeave" + event.currentTarget.id + bezeichnung);
  };
  // const onDragOver = (
  //   event: React.MouseEvent<HTMLDivElement>,
  //   bezeichnung: string
  // ) => {
  //   console.log("onDragOver" + event.currentTarget.id + bezeichnung);
  //   event.stopPropagation();
  //   event.preventDefault();
  // };

  const onDragOver = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>, bezeichnung: string) => {
      event.stopPropagation();
      event.preventDefault();
    },
    []
  );

  const onDrop = (
    event: React.MouseEvent<HTMLDivElement>,
    databaseSuperclass: string
  ) => {
    mutation.mutate(event.currentTarget.id);
  };
  const onDragEnd = () => {
    // localStorage.removeItem("ddDatabaseSuperclass");
    // localStorage.removeItem("ddId");
  };

  /**
   * styles
   ************************************************************************************
   */
  const styles = useStyles();

  /**
   * return
   ************************************************************************************
   */
  return (
    <div
      className={styles.card}
      onClick={onClickOpenUpdateDialog}
      key={"key" + data.id}
      id={data.id}
      draggable={true}
      onDragStart={(e) => onDragStart(e, dataDefinition.databaseSuperclass)}
      onDragEnter={(e) => onDragEnter(e, data.bezeichnung)}
      onDragLeave={(e) => onDragLeave(e, data.bezeichnung)}
      onDragOver={(e) => onDragOver(e, data.bezeichnung)}
      onDrop={(e) => onDrop(e, dataDefinition.databaseSuperclass)}
      onDragEnd={(e) => onDragEnd()}
    >
      {dataDefinition.CardComponent ? (
        <dataDefinition.CardComponent
          dataDefinition={dataDefinition}
          data={data}
        />
      ) : (
        data[dataDefinition.dataFields[0].name]
      )}
    </div>
  );
};
