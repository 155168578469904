import * as React from "react";
import _ from "lodash";
import { Controller, FieldError } from "react-hook-form";
import { Control, RegisterOptions } from "react-hook-form";
import { Checkbox, Input, Text, Textarea } from "@fluentui/react-components";

interface HookFormProps {
  name: string;
  control: Control<any>;
  rules?: RegisterOptions;
  defaultValue?: any;
  errorMessage?: any;
  type?:
    | "text"
    | "email"
    | "password"
    | "search"
    | "tel"
    | "url"
    | "date"
    | "datetime-local"
    | "month"
    | "number"
    | "time"
    | "week"
    | any;
}

export const ControlledTextField: React.FunctionComponent<
  HookFormProps | any
> = (props) => {
  return (
    <Controller
      name={props.name}
      key={"c" + props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={!_.isEmpty(props?.defaultValue) ? props?.defaultValue : ""}
      render={({
        field: { onChange, onBlur, name, value },
        fieldState: { isTouched, isDirty, error },
      }) => (
        <React.Fragment>
          <Input
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            value={value}
            key={"i" + props.name}
            type={props.type}
          />
          <Text>{props?.errorMessage}</Text>
        </React.Fragment>
      )}
    />
  );
};
