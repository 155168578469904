import React from "react";

import { IDataDefinition } from "../common/IDataDefinition";
import { DataCollection } from "../common/DataCollection";
import { SchadenCard } from "./SchadenCard";

import { UmbrellaRegular as IconSchaden } from "@fluentui/react-icons";
import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

const schadenDataDefinition: IDataDefinition = {
  reactQueryString: ["schaden"],
  databaseSuperclass: "kontext",
  pageCount: 25,
  icon: <IconSchaden color={tokens.colorBrandForegroundOnLight} />,
  captionCollection: "Schäden",
  captionEntity: "Schaden",
  readResourceCollection: "/rpc/fetch-all/schaden",
  readResourceEntity: "/schaden/",
  updateResourceEntity: "/schaden/",
  dataFields: [
    {
      art: "input",
      type: "text",
      name: "bezeichnung",
      label: "Bezeichnung",
      hint: "64 Zeichen",
    },
    {
      art: "input",
      type: "text",
      name: "kommentar",
      label: "Kommentar",
    },
  ],
  searchFields: [
    {
      art: "input",
      type: "text",
      urlParameter: "sb",
      label: "Bezeichnung",
      databaseTable: "schaden",
      databaseField: "bezeichnung",
      whereFilterType: "like",
      hint: "64 Zeichen",
    },
    {
      art: "input",
      type: "text",
      urlParameter: "sk",
      label: "Kommentar",
      databaseTable: "schaden",
      databaseField: "kommentar",
      whereFilterType: "like",
    },
  ],
};

const personDataDefinition: IDataDefinition = {
  reactQueryString: ["person"],
  databaseSuperclass: "partner",
  pageCount: 25,
  icon: <IconSchaden color={tokens.colorBrandForegroundOnLight} />,
  captionCollection: "Personen",
  captionEntity: "Person",
  readResourceCollection: "/rpc/fetch-all/person",
  readResourceEntity: "/partner/person/",
  updateResourceEntity: "/partner/person/",
  dataFields: [
    {
      art: "input",
      type: "text",
      name: "name",
      label: "Nachname",
      hint: "64 Zeichen",
    },
    {
      art: "input",
      type: "text",
      name: "vorname",
      label: "Vorname",
    },
  ],
  searchFields: [
    {
      art: "input",
      type: "text",
      urlParameter: "pn",
      label: "Nachname",
      databaseTable: "partner",
      databaseField: "name",
      whereFilterType: "like",
      hint: "64 Zeichen",
    },
    {
      art: "input",
      type: "text",
      urlParameter: "pv",
      label: "Vorname",
      databaseTable: "person",
      databaseField: "vorname",
      whereFilterType: "like",
      hint: "64 Zeichen",
    },
    // {
    //   art: "input",
    //   type: "text",
    //   urlParameter: "person_vorname",
    //   label: "Vorname",
    //   databaseTable: "person",
    //   databaseField: "vorname",
    //   whereFilterType: "like",
    // },
  ],
  CardComponent: SchadenCard,
};

const useStyles = makeStyles({
  componentContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    ...shorthands.gap(tokens.spacingHorizontalS),
  },
});

export const SchadenCollection: React.FunctionComponent<any> = () => {
  const styles = useStyles();

  return (
    <div className={styles.componentContainer}>
      <DataCollection dataDefinition={schadenDataDefinition} />
      <DataCollection dataDefinition={personDataDefinition} />
    </div>
  );
};
