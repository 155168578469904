import React, { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import _ from "lodash";

import { Routes, Route } from "react-router-dom";
import { Navigate, useLocation } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { IStore } from "../store/store";
import { settingActions } from "../store/settingStore";

import {
  FluentProvider,
  Theme,
  BrandVariants,
  createLightTheme,
  createDarkTheme,
} from "@fluentui/react-components";

import { LoginPage } from "./LoginPage";
import { MembersOnly } from "./MembersOnly";
import { NotFound } from "./NotFound";
import { Error } from "./Error";
import { Home } from "./Home";

/**
 * Kontext-Komponenten
 */
// import { Schadenereignis } from "./schadenereignis/Schadenereignis";
import { SchadenCollection } from "./schaden/SchadenCollection";

/**
 * Element-Komponenten
 */
import MailCollection from "./mail/MailCollection";
// const MailCollection = React.lazy(() => import("./mail/MailCollection"));

/**
 * weitere Komponenten
 */
import Legende from "./Legende";
// import Charting from "./chart/Charting";

const brandVariants: BrandVariants = {
  "10": "#000000",
  "20": "#180e07",
  "30": "#3d2210",
  "40": "#62371a",
  "50": "#874c24",
  "60": "#ab612e",
  "70": "#d07538",
  "80": "#f58a42",
  "90": "#f8ac79",
  "100": "#f9b384",
  "110": "#f9b384",
  "120": "#fac19b",
  "130": "#fbd4b9",
  "140": "#fcddc9",
  "150": "#fdece0",
  "160": "#ffffff",
};
const myLightTheme: Theme = {
  ...createLightTheme(brandVariants),
  fontSizeBase100: "14px",
  fontSizeBase200: "16px",
  fontSizeBase300: "16px",
  fontSizeBase400: "18px",
};
const myDarkTheme: Theme = {
  ...createDarkTheme(brandVariants),
  fontSizeBase100: "14px",
  fontSizeBase200: "16px",
  fontSizeBase300: "16px",
  fontSizeBase400: "18px",
};

const App: React.FunctionComponent<any> = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  const themeIsDark = useSelector(
    (state: IStore) => state.setting.designIsDark
  );

  /**
   * Design: light or dark mode
   */
  useEffect(() => {
    /**
     * Styling body
     */
    if (themeIsDark) {
      document.body.style.background = myDarkTheme.colorNeutralBackground1;
    } else {
      document.body.style.background = myLightTheme.colorNeutralBackground1;
    }
    document.body.style.margin = "0";

    /**
     * Kendo Theming
     */
    for (let i = 0; i < document.styleSheets.length; i++) {
      if (
        document.styleSheets[i].cssRules[0].cssText ===
        ".k-theme-test-class, .k-common-test-class { opacity: 0; }"
      ) {
        if (
          document.styleSheets[i].cssRules[23].cssText.indexOf(
            "color: black;"
          ) >= 0
        ) {
          if (themeIsDark) {
            document.styleSheets[i].disabled = false;
          } else {
            document.styleSheets[i].disabled = false;
            document.styleSheets[i].disabled = true;
          }
        }
        if (
          document.styleSheets[i].cssRules[23].cssText.indexOf(
            "color: white;"
          ) >= 0
        ) {
          if (themeIsDark) {
            document.styleSheets[i].disabled = true;
          } else {
            document.styleSheets[i].disabled = false;
          }
        }

        /**
         * @todo: charting needs a refresh of the page (change per navigate and come back directly)
         */
        // navigate("/notfound");
        // setTimeout(() => {
        //   navigate("/charting");
        // }, 1000);
      }
    }

    // console.log(document.styleSheets[i]);
    // console.log(document.styleSheets[i].cssRules.length);
    // if (
    //   document.styleSheets[i].cssRules[0].cssText ===
    //   ".k-theme-test-class, .k-common-test-class { opacity: 0; }"
    // ) {
    //   for (let j = 0; j < document.styleSheets[i].cssRules.length; j++) {
    //     if (
    //       ~document.styleSheets[i].cssRules[j].cssText.indexOf(
    //         "background"
    //       )
    //     ) {
    //       console.log(
    //         j + ": " + document.styleSheets[i].cssRules[j].cssText
    //       );
    //     }
    //   }
    // }
  }, [themeIsDark]);

  //Theme
  useEffect(() => {
    const onSelectMode = (mode: string) => {
      dispatch(settingActions.setDesign(mode));
    };

    // Add listener to update theme
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) =>
        onSelectMode(e.matches ? "dark" : "light")
      );

    // Setup dark/light mode for the first time
    onSelectMode(
      window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light"
    );

    // Remove listener
    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", () => {});
    };
  }, [dispatch]);

  //store current page in localStorage
  useEffect(() => {
    //set last page visit
    if (location.pathname.substring(1, 6) !== "login") {
      localStorage.setItem("page", location.pathname + location.search);
    }
  }, [location]);

  const ErrorFallback = () => {
    // return <Navigate to="/error" replace />;
    return (
      // <FluentProvider theme={themeIsDark ? myDarkTheme : myLightTheme}>
      //   <Error />
      // </FluentProvider>
      <div>Router Fehler</div>
    );
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={() => {
        window.location.reload();
      }}
    >
      <FluentProvider theme={themeIsDark ? myDarkTheme : myLightTheme}>
        <React.Suspense fallback="<Spinner />">
          <Routes>
            <Route
              path="/*"
              element={
                <RequireAuth>
                  <MembersOnly />
                </RequireAuth>
              }
              errorElement={<ErrorFallback />}
            >
              <Route path="" element={<Home />} />
              <Route path="home" element={<Home />} />
              <Route path="legende" element={<Legende />} />
              {/* <Route path="dashboard" element={<Dashboard />} /> */}
              <Route path="mail" element={<MailCollection />} />
              {/* <Route path="user" element={<UserBoard />} /> */}
              {/* <Route path="charting" element={<Charting />} /> */}
              {/* <Route path="schadenereignis" element={<Schadenereignis />} /> */}
              {/* <Route path="schaden/:schadenId" element={<DetailSchaden />} /> */}
              <Route path="schaden" element={<SchadenCollection />} />
              {/* <Route path="datei" element={<Datei />} /> */}
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="/error" element={<Error />} />
            <Route path="/login" element={<LoginPage />} />
          </Routes>
        </React.Suspense>
      </FluentProvider>
    </ErrorBoundary>
  );
};

//logged in?
// async function checkAccessToken(): Promise<void> {
//   const currentUser = await doHttpRequest("/user/current");
//   if (currentUser?.status === 200) {
//     dispatch(
//       authActions.login({
//         isAuthenticated: true,
//         accessToken: localStorage.getItem("token"),
//         isMicrosoft: false,
//         fullName: localStorage.getItem("user"),
//       })
//     );
//   } else {
//     localStorage.removeItem("token");
//   }
// }

function RequireAuth({ children }: { children: JSX.Element }) {
  // let location = useLocation();
  // let params = useParams();
  // let [searchParams, setSearchParams] = useSearchParams();

  // console.log("location.pathname", location.pathname);
  // console.log("urlParams", params.dashboard);
  // console.log("searchParams", searchParams.get("aq"));

  const token = useSelector((state: IStore) => state.auth.accessToken);

  // console.log(
  //   "location.pathname.substring(1, 5)",
  //   location.pathname.substring(1, 5)
  // );

  //ohne Token im localStorage umleiten auf die LoginSeite
  if (_.isEmpty(localStorage.getItem("token"))) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

export default App;
