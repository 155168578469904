import React from "react";
import _ from "lodash";

import { IDataDefinition } from "../common/IDataDefinition";
import { DataCollectionHeader } from "./DataCollectionHeader";
import { DataCollectionBody } from "./DataCollectionBody";

// import { useSearchFunctions } from "./useSearchFunctions";

import {
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  componentItem: {
    minWidth: "320px",
    ...shorthands.flex(0, 1, "400px"),
    boxShadow: tokens.shadow4,
  },

  columnContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    backgroundColor: tokens.colorNeutralBackground4,
  },

  columnItemHeader: {
    height: "44px",
  },

  columnItemContent: {
    height: "100%",
    ...shorthands.overflow("hidden", "auto"),
  },
});

export const DataCollection: React.FunctionComponent<{
  dataDefinition: IDataDefinition;
}> = ({ dataDefinition }) => {
  const styles = useStyles();

  /**
   * @todo wieder löschen
   */
  // const { getQueryKeyObject } = useSearchFunctions();
  // /** query key für collections */
  // const concatenated = _.concat(
  //   dataDefinition.reactQueryString,
  //   getQueryKeyObject(dataDefinition.searchFields)
  // );
  // console.log(concatenated);

  // /** query key für update */
  // console.log(_.concat(dataDefinition.reactQueryString, "entity", "601"));

  return (
    <React.Fragment>
      <div
        className={mergeClasses(styles.componentItem, styles.columnContainer)}
      >
        <div className={styles.columnItemHeader}>
          <DataCollectionHeader dataDefinition={dataDefinition} />
        </div>
        <div className={styles.columnItemContent}>
          <DataCollectionBody dataDefinition={dataDefinition} />
        </div>
      </div>
    </React.Fragment>
  );
};
