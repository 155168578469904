import { useSelector } from "react-redux";

import { ILanguage } from "../language/ILanguage";
import deText from "../language/de.json";
import enText from "../language/en.json";

export function useTranslation() {
  const language = useSelector((state: any) => state.setting.language);
  const t = (phrase: ILanguage, plural?: boolean) => {
    switch (language) {
      case "en_US":
        return enText[phrase];
      default:
        return deText[phrase];
    }
  };
  return t;
}

export default useTranslation;
