import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { IStore } from "./store";

const initialUpdateState: IStore["update"] = {
  editPanelIsVisible: false,
  confirmCloseHidden: true,
  saveButtonEnabled: true,
  savedSuccessfully: false,
  primaryId: "",
  caption: "",
  readResourceEntity: "",
  updateResourceEntity: "",
  dataFields: [],
  reactQueryString: [],
  searchFields: [],
};

const updateSlice = createSlice({
  name: "update",
  initialState: initialUpdateState,
  reducers: {
    reset(state) {
      _.assign(state, initialUpdateState);
    },

    showEditPanel(state, action) {
      //payload
      state.primaryId = action.payload.primaryId;
      state.caption = action.payload.caption;
      state.readResourceEntity = action.payload.readResourceEntity;
      state.updateResourceEntity = action.payload.updateResourceEntity;
      state.dataFields = action.payload.dataFields;
      state.reactQueryString = action.payload.reactQueryString;
      state.searchFields = action.payload.searchFields;

      //internal values
      state.savedSuccessfully = false;
      state.editPanelIsVisible = true;
      state.saveButtonEnabled = true;
    },
    hideEditPanel(state) {
      state.editPanelIsVisible = false;
    },

    //Schließen ohne Speichern
    showConfirmPanel(state) {
      state.confirmCloseHidden = false;
    },
    hideConfirmPanel(state) {
      state.confirmCloseHidden = true;
    },

    setSavedSuccessfully(state, action) {
      state.savedSuccessfully = action.payload;
    },

    //erfolgreich gespeichert
    savedSuccessfully(state) {
      state.savedSuccessfully = true;
    },

    //Save-Button to click only once
    disableSaveButton(state) {
      state.saveButtonEnabled = false;
    },
    enableSaveButton(state) {
      state.saveButtonEnabled = true;
    },
  },
});

export const updateActions = updateSlice.actions;

export default updateSlice.reducer;
