import React, { useState, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Config } from "../config/config";

import { doHttpRequest } from "../services/doHttpRequest";
import { useUserAuth } from "../services/useUserAuth";

import { IStore } from "../store/store";
import { useSelector, useDispatch } from "react-redux";
import { settingActions } from "../store/settingStore";

import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "../services/useTranslation";

import { HeaderMenu } from "./HeaderMenu";
import { FeedbackOnSave } from "./editDialog/FeedbackOnSave";

import {
  Popover,
  PopoverTrigger,
  PopoverSurface,
  PopoverProps,
  PopoverTriggerChildProps,
} from "@fluentui/react-components";

import {
  Button,
  CounterBadge,
  Badge,
  Avatar,
  MenuItemProps,
} from "@fluentui/react-components";

import {
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuDivider,
  MenuGroup,
  MenuGroupHeader,
  MenuPopover,
  MenuItemRadio,
  MenuProps,
} from "@fluentui/react-components";

import {
  Home24Regular as IconHome,
  ArrowMoveInwardFilled as IconEreignis,
  UmbrellaRegular as IconSchaden,
  DocumentToolboxRegular as IconVersichertePositionen,
  BoxMultipleRegular as IconSachen,
  BinFullFilled as IconGewerk,
  Mail24Regular as IconMail,
  PeopleRegular as IconPerson,
  PersonArrowLeftRegular as IconLogout,
  LocalLanguageRegular as IconLanguage,
  CurrencyDollarEuroRegular as IconCurrency,
  AccessTimeRegular as IconTimeZone,
  GridDots24Regular as IconGrid,
  FolderOpenRegular as IconFolder,
  ChartMultipleRegular as IconChart,
  SlideLayoutRegular as IconLayout,
  ShareScreenPersonOverlayRegular as IconDashboard,
  WeatherSunnyRegular as IconDesignLight,
  WeatherMoonFilled as IconDesignDark,
  DarkThemeRegular as IconDesignBrowser,
} from "@fluentui/react-icons";

import {
  Text,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  pageItemHeader: {
    maxHeight: "32px",
    ...shorthands.flex(1, 1, "auto"),
    ...shorthands.padding(tokens.spacingVerticalS),
    ...shorthands.borderTop(
      tokens.strokeWidthThick,
      "solid",
      tokens.colorBrandBackground
    ),
    ...shorthands.borderBottom(
      tokens.strokeWidthThin,
      "solid",
      tokens.colorBrandBackground
    ),
  },
  headerContainer: {
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    alignItems: "center",
  },
  headerItem: {
    ...shorthands.flex(1, 1, "auto"),
    ...shorthands.padding("0"),
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    alignItems: "center",
  },
  justifyStart: { justifyContent: "flex-start" },
  justifyEnd: { justifyContent: "flex-end" },

  badgeTransform: { transform: "translate(-12px, -7px)" },
});

export const Header: React.FunctionComponent = () => {
  const styles = useStyles();
  const { userLogout } = useUserAuth();

  const [mailCount, setMailCount] = useState(undefined);
  const isMicrosoft = useSelector((state: IStore) => state.auth.isMicrosoft);

  const navigate = useNavigate();
  const t = useTranslation();

  const location = useLocation();

  /**
   * Server - sent - events;
   */
  useEffect(() => {
    let listenToServer: EventSource;
    async function getJwtToken(): Promise<void> {
      const data = await doHttpRequest("/oauth/token");
      startServerSentEvent(data?.data.access_token);
    }

    const startServerSentEvent = (jwtToken: string) => {
      try {
        listenToServer = new EventSource(
          Config.apiUrl + "/server-sent-events?access_token=" + jwtToken,
          {
            withCredentials: true,
          }
        );

        listenToServer.addEventListener(
          "mailcount",
          (event: any) => {
            setMailCount(event.data);
          },
          false
        );
        // Remove listener
      } catch {}
    };

    /**
     * @todo isMicrosoft muss an dieser Stelle gesetzt sein; dann die if-clause wieder einkommentieren
     */
    // if (isMicrosoft) {
    getJwtToken();
    // }

    return () => {
      // if (isMicrosoft) {
      listenToServer.close();
      // }
    };
  }, []);

  //get state from store
  const dispatch = useDispatch();

  const [isCalloutVisible, setCalloutVisible] = useState(false);

  const toggleCalloutVisible = (
    event: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    setCalloutVisible(!isCalloutVisible);
  };
  const languageCode = useSelector((state: IStore) => state.setting.language);

  const designIsDark = useSelector(
    (state: IStore) => state.setting.designIsDark
  );

  const ausloggen = () => {
    userLogout();
  };

  const toggleLanguage = () => {
    dispatch(settingActions.setLanguage(""));
    setCalloutVisible(false);
  };

  /***
   * Design
   ***/
  const design = useSelector((state: IStore) => state.setting.design);
  const language = useSelector((state: IStore) => state.setting.language);
  const currency = useSelector((state: IStore) => state.setting.currency);
  const timezone = useSelector((state: IStore) => state.setting.timezone);
  const onChangeDesignValue: MenuProps["onCheckedValueChange"] = () => {};

  const menuItemClickHandler = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    const menuItem: MenuItemProps = event.currentTarget;
    // const button: HTMLButtonElement = event.currentTarget;
    navigate("" + menuItem.id);
  };

  const MenuItemWrapper: React.JSXElementConstructor<{
    Icon: {
      (
        props: React.SVGAttributes<SVGElement> & {
          primaryFill?: string | undefined;
          className?: string | undefined;
          filled?: boolean | undefined;
          title?: string | undefined;
        }
      ): JSX.Element;
      displayName: string | undefined;
    };
    link: string;
    text: string;
  }> = ({ Icon, link, text }) => {
    return (
      <MenuItem
        // {...(this.props.editable ? {editable: this.props.editableOpts} : {})}
        icon={
          <Icon
            color={
              location.pathname === link
                ? tokens.colorBrandForegroundOnLight
                : undefined
            }
          />
        }
        id={link}
        onClick={menuItemClickHandler}
      >
        <Text weight={location.pathname === link ? "semibold" : undefined}>
          {text}
        </Text>
      </MenuItem>
    );
  };

  const Breadcrumb = () => {
    const location = useLocation();
    const styles = useStyles();

    return;
    //  (
    // <div className={styles.headerItemNextToLeft}>
    //   <div className={styles.iconFlexItem}>
    //     <IconDashboard />
    //   </div>
    //   <div className={styles.textFlexItem}>
    //     <Text>
    //       {location.pathname === "/dashboard" ? "Dashboard" : "Startseite"}
    //     </Text>
    //   </div>
    // </div>
    // );
  };

  const savedSuccessfully = useSelector(
    (state: IStore) => state.update.savedSuccessfully
  );

  const ErrorFallback = () => {
    return <HeaderMenu />;
  };

  return (
    <div
      className={mergeClasses(styles.pageItemHeader, styles.headerContainer)}
    >
      <div className={mergeClasses(styles.headerItem, styles.justifyStart)}>
        <div>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={() => {
              // window.location.reload();
            }}
          >
            <HeaderMenu />
          </ErrorBoundary>
        </div>
      </div>
      <div className={mergeClasses(styles.headerItem, styles.justifyEnd)}>
        <div>
          <Popover
            open={savedSuccessfully}
            positioning={"above-end"}
            // appearance={"inverted"}
          >
            <PopoverTrigger>
              <div>
                <Button
                  appearance="subtle"
                  icon={<IconMail />}
                  size="large"
                  onClick={() => {
                    /**
                     * @todo export aufrufen beim Login + Button zum manuellen Anstoßen + Logik im Backend
                     */
                    // doHttpRequest("/microsoft/contacts");
                    //doHttpRequest("/microsoft/contacts/export");
                    navigate("/mail");
                  }}
                />
                <CounterBadge
                  className={styles.badgeTransform}
                  count={mailCount}
                />
              </div>
            </PopoverTrigger>
            <PopoverSurface>
              <FeedbackOnSave />
            </PopoverSurface>
          </Popover>
        </div>
      </div>
    </div>
  );
};
