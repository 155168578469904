import React from "react";
import { IDataDefinition } from "../common/IDataDefinition";

export const SchadenCard: React.FunctionComponent<{
  dataDefinition: IDataDefinition;
  data: any;
}> = ({ dataDefinition, data }) => {
  return (
    <div>
      {data &&
        dataDefinition &&
        data[dataDefinition.dataFields[0].name] +
          " :-) " +
          data[dataDefinition.dataFields[1].name]}
    </div>
  );
};
