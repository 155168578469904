import React, { useState, useEffect, useRef } from "react";

import { Spinner } from "@fluentui/react-components";

export const DelayedSpinner: React.FunctionComponent<{
  delayTime?: number;
}> = ({ delayTime = 500 }) => {
  const [count, setCount] = useState(0);
  const countRef = useRef(count);

  useEffect(() => {
    const timer = setInterval(() => {
      if (countRef.current >= delayTime) {
        clearInterval(timer);
      } else {
        countRef.current = countRef.current + 100;
        setCount(countRef.current);
      }
    }, 100);
  }, []);

  return countRef.current >= delayTime ? (
    <Spinner label="Daten werden geladen" />
  ) : null;
};
