import React from "react";
import _ from "lodash";

import { IDataDefinition, ISearchField } from "../common/IDataDefinition";
import { useSearchFunctions } from "./useSearchFunctions";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../store/store";
import { InputControlled } from "./basicInput/InputControlled";

import { useSearchParams } from "react-router-dom";

import { useForm, FormProvider } from "react-hook-form";

import { Input, Button, ButtonProps, Text } from "@fluentui/react-components";

import { makeStyles, shorthands } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("20px"),
    // Prevent the example from taking the full width of the page (optional)
    maxWidth: "320px",
    width: "100%",
    // Stack the label above the field (with 2px gap per the design system)
    "> div": {
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("2px"),
    },
  },
});

export const SearchFields: React.FunctionComponent<{
  dataDefinition: IDataDefinition;
  changeData: any;
}> = ({ dataDefinition, changeData }) => {
  const urlSuchParameter = useSelector(
    (state: IStore) => state.search.urlSuchParameter
  );
  const { debouncedSearch } = useSearchFunctions();

  const styles = useStyles();

  // also status === 'success', but "else" logic works, too
  return (
    <div className={styles.root}>
      <div>
        <Text size={500} weight="bold">
          Suche
        </Text>
      </div>
      {dataDefinition.searchFields.map((searchField: ISearchField, index) => {
        return (
          <div key={index}>
            {/* <Text>{searchField.label}</Text> */}
            <Input
              name={"search" + searchField.urlParameter}
              defaultValue={urlSuchParameter[searchField.urlParameter]}
              placeholder={searchField.label}
              type={"text"}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                debouncedSearch(searchField.urlParameter, e.target.value);
                changeData(e.target.value);
              }}
            />
          </div>
        );
      })}
    </div>
  );
};
