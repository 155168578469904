import { Config } from "../config/config";
import axios, { AxiosPromise } from "axios";

type IHttpMethod = "get" | "post" | "patch";
type IHeaderAuthorization = "ACCESS_TOKEN" | "API_BASIC_AUTH";

export async function rpcFetchAll(resourceUrl: string) {
  try {
    const response = await axios({
      url: Config.apiUrl + resourceUrl,
      method: "get",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        Accept: "application/json",
      },
      responseType: "json",
    });

    // const response = await axios.get(
    //   "https://api.github.com/repos/facebook/react/issues?page=1"
    // );

    //Logging in DEV
    process.env.NODE_ENV !== "production" &&
      console.log("inside rpcFetchAll", {
        status: response.status,
        resourceUrl: resourceUrl,
        response: response,
      });

    return response.data.collection;
  } catch (error: any) {
    //Logging in DEV
    process.env.NODE_ENV !== "production" &&
      console.log("error inside rpcFetchAll", {
        status: error?.response?.status,
        resourceUrl: resourceUrl,
        response: error,
      });

    //nicht authentifiziert, zur LoginSeite
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
    }

    return error.response;
  }
}

export async function peritosFetch(resourceUrl: string) {
  try {
    const response = await axios({
      url: Config.apiUrl + resourceUrl,
      method: "get",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        Accept: "application/json",
      },
      responseType: "json",
      // timeout: 250,
    });

    //Logging in DEV
    process.env.NODE_ENV !== "production" &&
      console.log("inside peritosFetch", {
        status: response.status,
        resourceUrl: resourceUrl,
        response: response,
      });

    return response.data;
  } catch (error: any) {
    //Logging in DEV
    process.env.NODE_ENV !== "production" &&
      console.log("error inside peritosFetch", {
        status: error?.response?.status,
        resourceUrl: resourceUrl,
        response: error,
      });

    return error.response;
  }
}

export async function doHttpRequest<ResultType = Record<string, any>>(
  resourceUrl: string,
  httpMethod: IHttpMethod = "get",
  jsonBody: any = {},
  headerAuthorization: IHeaderAuthorization = "ACCESS_TOKEN"
): Promise<AxiosPromise<ResultType> | undefined> {
  const bearerToken =
    headerAuthorization === "ACCESS_TOKEN"
      ? "Bearer " + localStorage.getItem("token")
      : Config.apiBasicAuth;

  try {
    const response = await axios({
      url: Config.apiUrl + resourceUrl,
      method: httpMethod,
      headers: {
        Authorization: bearerToken,
        Accept: "application/json",
      },
      responseType: "json",
      // timeout: 250,
      data: jsonBody,
    });

    //Logging in DEV
    process.env.NODE_ENV !== "production" &&
      console.log("inside doHttpRequest", {
        status: response.status,
        resourceUrl: resourceUrl,
        response: response,
      });

    return response;
  } catch (error: any) {
    //Logging in DEV
    process.env.NODE_ENV !== "production" &&
      console.log("error inside doHttpRequest", {
        status: error?.response?.status,
        resourceUrl: resourceUrl,
        response: error,
      });
    return error.response;
  }
}
