import React from "react";

import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { Button } from "@fluentui/react-components";

import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";

import { Lottie } from "../lotties/Lottie";

const useStyles = makeStyles({
  flexWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    "@media screen and (min-width: 768px)": {
      marginTop: tokens.spacingVerticalXXXL,
    },
  },
});

export const Home: React.FunctionComponent<any> = () => {
  const styles = useStyles();

  const {
    Bold,
    Italic,
    Underline,
    AlignLeft,
    AlignRight,
    AlignCenter,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    Link,
    Unlink,
    FontSize,
    ForeColor,
  } = EditorTools;

  const editor = React.useRef<Editor>(null);

  const getHtml = () => {
    if (editor.current) {
      const view = editor.current.view;
      if (view) {
        // console.log(EditorUtils.getHtml(view.state));

        /**
         * @todo entfernen nach Prüfung: Kendo Theming
         */
        for (let i = 0; i < document.styleSheets.length; i++) {
          console.log(document.styleSheets[i].cssRules.length);
          console.log(document.styleSheets[i]);
        }
      }
    }
  };

  return (
    <React.Fragment>
      <Editor
        ref={editor}
        defaultEditMode="div"
        // onChange={getHtml}
        tools={[
          [Bold, Italic, Underline],
          [OrderedList, UnorderedList, Indent, Outdent],
          [Undo, Redo],
          [Link, Unlink],
          [FontSize, ForeColor],
          [AlignLeft, AlignCenter, AlignRight],
        ]}
        contentStyle={{
          height: 320,
          fontSize: "16px",
        }}
        defaultContent={
          "<p></p><p></p><p>Mit freundlichen Grüßen</p><p>Andreas Jacobs</p>" +
          '<p>&nbsp;</p><p>Tel.: +49 2521 29909-22</p><p>Mobil: +49 1514 2649735</p><p>E-Mail: <a href="mailto:ajacobs@peritos-gmbh.de" title="E-Mail an ajacobs@peritos-gmbh.de">ajacobs@peritos-gmbh.de</a></p>' +
          '<p>&nbsp;</p><p>Peritos GmbH</p><p>Sternstraße 7, 59269 Beckum</p><p><a href="https://www.peritos-gmbh.de" title="Peritos GmbH Homepage">www.peritos-gmbh.de</a></p><p><a href="https://de.linkedin.com/company/peritos-gmbh" title="Peritos GmbH auf LinkedIn">Peritos GmbH | LinkedIn</a></p><span style="font-size: xx-small;"><p>Amtsgericht Münster HRB Nr. 11232</p><p>Geschäftsführer: Kathrin Jacobs, Harald Vollgraf</p></span>'
        }
        //replace <p></p> with <p>&nbsp;</p>
        //add <style>p{margin: 0}</style> to an e-mail
        // "<style>p{margin: 0}</style><p>&nbsp;</p><p>&nbsp;</p><p style=\"margin: 0;\">Mit freundlichen Grüßen</p><p style=\"margin: 0;\">Andreas Jacobs</p><p>&nbsp;</p><p>Tel.: +49 2521 29909-22</p><p>Mobil: +49 1514 2649735</p><p>E-Mail: <a href=\"mailto:ajacobs@peritos-gmbh.de\" title=\"E-Mail an ajacobs@peritos-gmbh.de\">ajacobs@peritos-gmbh.de</a></p><p>&nbsp;</p><p>Peritos GmbH</p><p>Sternstraße 7, 59269 Beckum</p><p><a href=\"https://www.peritos-gmbh.de\" title=\"Peritos GmbH Homepage\">www.peritos-gmbh.de</a></p><p><a href=\"https://de.linkedin.com/company/peritos-gmbh\" title=\"Peritos GmbH auf LinkedIn\">Peritos GmbH | LinkedIn</a></p><p><span style=\"font-size: xx-small;\">Amtsgericht Münster HRB Nr. 11232</span></p><p><span style=\"font-size: xx-small;\">Geschäftsführer: Kathrin Jacobs, Harald Vollgraf</span></p>"
      />
      <Button
        onClick={() => {
          getHtml();
        }}
      >
        getHtml
      </Button>
    </React.Fragment>
  );
};
