import _ from "lodash";
import { useDispatch } from "react-redux";
import { authActions } from "../store/authStore";
import { settingActions } from "../store/settingStore";
import { searchActions } from "../store/searchStore";
import { updateActions } from "../store/updateStore";

import { useNavigate } from "react-router-dom";

import { doHttpRequest } from "./doHttpRequest";
import { IUser } from "../entity/IUser";

export function useUserAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function userLogout(): Promise<void> {
    //aus Datenbank löschen
    if (!_.isEmpty(localStorage.getItem("token"))) {
      await doHttpRequest("/oauth/revoke", "post", {
        token: localStorage.getItem("token"),
        token_type_hint: "access_token",
      });
    }

    //aus local storage löschen
    localStorage.removeItem("page");
    localStorage.removeItem("token");

    //reset Redux-Store
    dispatch(authActions.reset());
    dispatch(settingActions.reset());
    dispatch(searchActions.reset());
    dispatch(updateActions.reset());

    //zur Login-Seite navigieren
    console.log("NAVIGATE to /login FROM UserLogout");
    navigate("/login");
  }

  async function userLogin(requestBody: any, isMicrosoft: boolean = false) {
    const response = await doHttpRequest<IUser>(
      "/oauth",
      "post",
      requestBody,
      "API_BASIC_AUTH"
    );
    if (response?.status === 200) {
      localStorage.setItem("token", response!.data.access_token);
      await userToStore(isMicrosoft);
      //zuletzt angezeigte Seite oder Home
      let link = localStorage.getItem("page");
      link = _.isEmpty(link) ? "/home" : link;
      navigate("" + link, { replace: true });
    }
    return response;
  }

  async function userToStore(isMicrosoft: boolean = false): Promise<void> {
    const user = await doHttpRequest<IUser>("/user/current");
    if (!_.isEmpty(user)) {
      //authStore
      dispatch(
        authActions.login({
          userId: user!.data.id,
          isAuthenticated: true,
          accessToken: localStorage.getItem("token"),
          isMicrosoft,
          fullName: user!.data.full_name,
        })
      );

      //settingStore
      dispatch(settingActions.setLanguage(user?.data.language));
    } else {
      userLogout();
    }
  }

  return { userLogin, userLogout, userToStore };
}
