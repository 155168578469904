import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { IStore } from "./store";

const initialSearchState: IStore["search"] = {
  /**
   * Objekt mit key-value pairs der Suchparameter + Abfragen pro Seite
   */
  urlSuchParameter: {},
  apiAbfragen: {},
};

const searchSlice = createSlice({
  name: "search",
  initialState: initialSearchState,
  reducers: {
    initializeUrlSuchParameter(state, action) {
      state.urlSuchParameter = action.payload;
    },
    setUrlSuchParameter(state, action) {
      state.urlSuchParameter[action.payload.parameter] = action.payload.value;
    },
    setCount(state, action) {
      state.apiAbfragen[action.payload.reactQueryString] =
        action.payload.anzahl;
    },
    setTest(state, action) {
      state.apiAbfragen[action.payload.test] = action.payload.anzahl;
    },
    /**
     * wird beim Ausloggen aufgerufen
     * @todo: einfacher wäre ein reload der Seite nach dem Ausloggen
     * */
    reset(state) {
      _.assign(state, initialSearchState);
    },
  },
});

export const searchActions = searchSlice.actions;

export default searchSlice.reducer;
