import React, { useState, useEffect, useRef } from "react";

import { useDispatch } from "react-redux";
import { updateActions } from "../../store/updateStore";
import { Alert, Progress } from "@fluentui/react-components/unstable";
import { makeStyles, shorthands } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("10px"),
  },
});

// function useInterval(initialValue: number, ms: number) {
//   const dispatch = useDispatch();
//   const [value, setValue] = React.useState(initialValue);
//   const intervalRef = React.useRef<any>(null);
//   let val = 0;

//   const start = React.useCallback(() => {
//     if (intervalRef.current !== null) {
//       return;
//     }

//     intervalRef.current = setInterval(() => {
//       if (val < 100) {
//         setValue((v) => v + 1);
//         val = val + 1;
//       }
//       if (val >= 100) {
//         dispatch(updateActions.setSavedSuccessfully(false));
//         stop();
//         setValue(0);
//       }
//     }, ms);
//   }, []);

//   const stop = React.useCallback(() => {
//     if (intervalRef.current === null) {
//       return;
//     }
//     clearInterval(intervalRef.current);
//     intervalRef.current = null;
//   }, []);

//   // const reset = React.useCallback(() => {
//   //   setValue(0);
//   //   val = 0;
//   // }, []);
//   return { value, start, stop };
// }

// export const FeedbackOnSave: React.FunctionComponent = () => {
//   const styles = useStyles();

//   const { value, start } = useInterval(0, 2700);

//   React.useEffect(() => {
//     start();
//   });

//   return (
//     <div className={styles.root}>
//       <Alert intent="success">
//         Speichern erfolgreich. Das Fenster schließt automatisch.
//         <br />
//         <br />
//       </Alert>
//       <Progress
//         value={value}
//         max={90}
//         thickness={"large"}
//         validationState={"success"}
//       />
//     </div>
//   );
// };

export const FeedbackOnSave: React.FunctionComponent<{
  delayTime?: number;
}> = ({ delayTime = 3000 }) => {
  const styles = useStyles();

  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const countRef = useRef(count);

  useEffect(() => {
    const timer = setInterval(() => {
      if (countRef.current >= delayTime) {
        dispatch(updateActions.setSavedSuccessfully(false));
        clearInterval(timer);
      } else {
        countRef.current = countRef.current + 100;
        setCount(countRef.current);
      }
    }, 100);
  }, []);

  return (
    <div className={styles.root}>
      <Alert intent="success">
        Speichern erfolgreich. Das Fenster schließt automatisch.
        <br />
        <br />
      </Alert>
      <Progress
        value={countRef.current}
        max={delayTime * 0.95}
        thickness={"large"}
        validationState={"success"}
      />
    </div>
  );
};
