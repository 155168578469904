import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./authStore";
import searchReducer from "./searchStore";
import updateReducer from "./updateStore";
import settingReducer from "./settingStore";

import { IDataField, ISearchField } from "../components/common/IDataDefinition";

interface IObjekt {
  [key: string]: string;
}

export interface IStore {
  auth: {
    userId: number;
    isAuthenticated: boolean;
    isMicrosoft: boolean;
    accessToken: string;
    fullName: string;
  };
  search: {
    urlSuchParameter: IObjekt;
    apiAbfragen: IObjekt;
  };
  setting: {
    design: "dark" | "light" | "browser";
    designIsDark: boolean;
    language: "en_GB" | "de_DE" | "it_IT";
    currency: "EUR" | "USD";
    timezone: "Europe/Berlin";
  };
  update: {
    /**
     * Datenfelder
     */
    primaryId: string;
    caption: string;
    readResourceEntity: string;
    updateResourceEntity: string;
    dataFields: IDataField[];
    reactQueryString: string[];
    searchFields: ISearchField[];

    /**
     * Sichtbarkeit der Komponenten
     */
    editPanelIsVisible: boolean;
    confirmCloseHidden: boolean;
    saveButtonEnabled: boolean;
    savedSuccessfully: boolean;
  };
}

const store = configureStore({
  reducer: {
    auth: authReducer,
    setting: settingReducer,
    search: searchReducer,
    update: updateReducer,
  },
});

export default store;
