//Client: peritosclient
//Passwort: testpass
// generate password via php
//C:\xampp\htdocs\backend>php vendor/laminas-api-tools/api-tools-oauth2/bin/bcrypt.php test
//$2y$10$5jicvhqg3n9Ey.uswcnkIOOGEFJzOeMtwK/6Trkh36/9PX9uBEG3i

export function urlParamEncode(str: string) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return "%" + c.charCodeAt(0).toString(16);
  });
}

export const Config = {
  // apiUrl: "https://plattform.peritos-gmbh.de/api",
  apiUrl:
    process.env.NODE_ENV !== "production"
      ? "http://localhost:8080"
      : "https://clavis.peritos-gmbh.de/api",

  apiBasicAuth: "Basic cGVyaXRvc2NsaWVudDp0ZXN0cGFzcw==",
};
