import React, { useEffect, useState } from "react";
import _ from "lodash";
import { IStore } from "../../store/store";
import { useQuery } from "@tanstack/react-query";
import { doHttpRequest } from "../../services/doHttpRequest";

import { useSelector } from "react-redux";

import { useFormContext } from "react-hook-form";
import { ControlledTextField } from "../common/basicInput/Textfeld";
import { Text } from "@fluentui/react-components";
import { makeStyles, shorthands } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("20px"),
    // Prevent the example from taking the full width of the page (optional)
    maxWidth: "400px",
    // Stack the label above the field (with 2px gap per the design system)
    "> div": {
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("2px"),
    },
  },
});

/**
 * @TOTO CUSTOMIZE
 */
export const FormFields: React.FunctionComponent<{
  primaryId: string;
  readResourceEntity: string;
  dataFields: any[];
  reactQueryString: string[];
}> = ({ primaryId, readResourceEntity, dataFields, reactQueryString }) => {
  const methods = useFormContext();

  const {
    formState: { errors },
  } = useFormContext<any>();
  const { isLoading, isError, isFetching, error, data } = useQuery<any, Error>({
    queryKey: _.concat(reactQueryString, "entity", primaryId),
    queryFn: async () => {
      return await doHttpRequest(readResourceEntity + primaryId);
    },
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Errore: {error.message}</span>;
  }

  // also status === 'success', but "else" logic works, too
  return (
    <React.Fragment>
      {!_.isEmpty(data.data) && (
        <React.Fragment>
          {dataFields.map((field, index) => {
            return (
              <React.Fragment key={index}>
                <br />
                <Text>{field.label}</Text>
                <ControlledTextField
                  control={methods.control}
                  name={field.name}
                  defaultValue={data.data[field.name]}
                  key={"ctf" + field.name}
                  type={field.type}
                  errorMessage={
                    errors[field.name] ? errors[field.name]?.message : ""
                  }
                />
              </React.Fragment>
            );
          })}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
