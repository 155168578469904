import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Config } from "../config/config";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "../services/useTranslation";

import { useLocation, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { authActions } from "../store/authStore";

import { useUserAuth } from "../services/useUserAuth";
// import { userLogin } from "../services/UserService";
// import { UserServiceMicrosoft } from "../services/UserServiceMicrosoft";

import {
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";

import {
  useId,
  Image,
  Input,
  Label,
  Button,
  Divider,
  Text,
  Title1,
  Subtitle2,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  flexWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    // marginTop: tokens.spacingVerticalXXXL,
    "@media screen and (min-width: 768px)": {
      marginTop: tokens.spacingVerticalXXXL,
    },
  },
  flexItem: {
    ...shorthands.flex(1, 1, "auto"),
    maxWidth: "600px",
    boxShadow: tokens.shadow16,
    ...shorthands.padding(tokens.spacingVerticalXL),
  },

  flexContent: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    ...shorthands.gap("0", tokens.spacingVerticalXL),
  },
  content: {
    ...shorthands.flex(1, 1, "auto"),
    "@media screen and (min-width: 768px)": {
      marginTop: tokens.spacingVerticalMNudge,
    },
  },

  paddingRight: {
    "@media screen and (min-width: 768px)": {
      paddingRight: "134px",
    },
  },

  field: {
    display: "grid",
    gridRowGap: tokens.spacingVerticalXXS,
    // marginTop: tokens.spacingVerticalMNudge,
    ...shorthands.padding(
      tokens.spacingHorizontalS,
      "0"
      // tokens.spacingHorizontalXXXL
    ),
  },
});

export const LoginPage: React.FunctionComponent<any> = () => {
  const styles = useStyles();
  const { userLogin } = useUserAuth();
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const t = useTranslation();

  const search = useLocation().search;
  const navigate = useNavigate();
  const stateLocation = useLocation();

  const [isLoggingIn, setIsLoggingIn] = useState(false);

  //existiert ein Token im LocalStorage?
  useEffect(() => {
    if (!_.isEmpty(localStorage.getItem("token"))) {
      dispatch(
        authActions.login({
          accessToken: localStorage.getItem("token"),
          userDetails: localStorage.getItem("user"),
        })
      );
      navigate("" + localStorage.getItem("page"), { replace: true });
    }
  }, [dispatch, navigate]);

  //wurde über DrittLogin Token übergeben?
  async function loginMicrosoft(
    requestBody: any,
    tokenViaGet: string | null
  ): Promise<void> {
    const status = await userLogin(requestBody, true);
    setIsLoggingIn(false);
    if (status?.status !== 200) {
      console.log("Fehler bei DrittLogin; status:", status);
      console.log("Fehler bei DrittLogin; tokenViaGet:", tokenViaGet);
    }
  }
  useEffect(() => {
    const tokenViaGet = new URLSearchParams(search).get("token");
    if (!_.isEmpty(tokenViaGet)) {
      setIsLoggingIn(true);
      const requestBody = {
        grant_type: "urn:ietf:params:oauth:grant-type:jwt-bearer",
        audience: "peritosclient",
        assertion: tokenViaGet,
      };

      loginMicrosoft(requestBody, tokenViaGet);
    }
  }, []);

  const emailId = useId("email");
  const passwordId = useId("password");
  //State of InputFields
  const [inputField, setInputField] = useState({
    [emailId]: "",
    [passwordId]: "",
  });

  //state und value der Inputfelder synchron halten
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | any,
    data: any
  ) => {
    setInputField({ ...inputField, [event.target.id]: event.target.value });
  };

  // Login mit Username und Passwort
  const handleSubmit = async (event: React.FormEvent) => {
    //clear cache for react-query
    queryClient.clear();

    //no full reload
    event.preventDefault();
    setIsLoggingIn(true);
    const requestBody = {
      grant_type: "password",
      username: inputField[emailId],
      password: inputField[passwordId],
    };
    //@todo: Fehler abfangen, wenn username leer ist
    const status = await userLogin(requestBody);
    if (status?.status !== 200) {
      alert("Benutzername oder Kennwort sind falsch!");
    }
    setIsLoggingIn(false);
  };

  return (
    <div className={styles.flexWrapper}>
      <div className={styles.flexItem}>
        <div className={styles.flexContent}>
          <div className={mergeClasses(styles.content, styles.paddingRight)}>
            <Image alt="Peritos Logo" src="peritos.png" fit="contain" />
          </div>
          <div className={styles.content}>
            <Title1>Willkommen zur Schadenplattform</Title1>
            <Subtitle2 block>
              Immer alle aktuellen Informationen an einem Ort
            </Subtitle2>
          </div>
          <div className={styles.content}>
            <form onSubmit={handleSubmit}>
              <div className={styles.field}>
                <Label htmlFor={emailId}>E-Mail</Label>
                <Input
                  id={emailId}
                  value={inputField[emailId]}
                  type="email"
                  onChange={handleChange}
                />
              </div>
              <div className={styles.field}>
                <Label htmlFor={emailId}>Passwort</Label>
                <Input
                  id={passwordId}
                  value={inputField[passwordId]}
                  type="password"
                  onChange={handleChange}
                />
              </div>
              <div>
                <Button
                  type="submit"
                  size="large"
                  appearance="primary"
                  onClick={handleSubmit}
                  disabledFocusable={isLoggingIn}
                >
                  Anmelden
                </Button>
              </div>
            </form>
          </div>
          <div className={styles.content}>
            <Divider alignContent="start">
              <Text>oder Login per Microsoft-Account</Text>
            </Divider>
          </div>
          <div className={styles.content}>
            <Button
              size="large"
              disabledFocusable={isLoggingIn}
              onClick={() => {
                setIsLoggingIn(true);
                window.location.href =
                  Config.apiUrl + "/aad-signin?provider=microsoft-graph";
              }}
            >
              Microsoft Login
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
