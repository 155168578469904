import React from "react";
import _ from "lodash";

import { IDataDefinition } from "./IDataDefinition";
import { IStore } from "../../store/store";

import { useSearchFunctions } from "./useSearchFunctions";

import { useSelector } from "react-redux";

import { Button, Input } from "@fluentui/react-components";
import { SearchFields } from "./SearchFields";

import {
  ChevronDownRegular as IconOpenAdvancedSearch,
  SearchFilled as IconSearch,
} from "@fluentui/react-icons";

import {
  Popover,
  PopoverTrigger,
  PopoverSurface,
  PopoverProps,
  PopoverTriggerChildProps,
} from "@fluentui/react-components";

import {
  makeStyles,
  tokens,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  searchField: {
    maxWidth: "128px",
  },
});

export const MainTextSearchField: React.FunctionComponent<{
  dataDefinition: IDataDefinition;
}> = ({ dataDefinition }) => {
  /**
   * interner Cache
   ************************************************************************************
   */

  const urlSuchParameter = useSelector(
    (state: IStore) => state.search.urlSuchParameter
  );

  /**
   * externer Cache + API-Aufruf
   ************************************************************************************
   */

  /**
   * Routing
   ************************************************************************************
   */

  const { debouncedSearch, getQueryKeyObject } = useSearchFunctions();

  /**
   * Side effects
   ************************************************************************************
   */

  /**
   * AdvancedSearch
   */
  const [target, setTarget] = React.useState<HTMLElement | null>(null);

  /**
   * component logic
   ************************************************************************************
   */

  /**
   * Popover
   */
  const [openAdvancedSearch, setOpenAdvancedSearch] = React.useState(false);
  const handleOpenChange: PopoverProps["onOpenChange"] = (e, data) =>
    setOpenAdvancedSearch(data.open || false);

  const AdvancedSearchButton = React.forwardRef<
    HTMLButtonElement,
    Partial<PopoverTriggerChildProps>
  >((props, ref) => {
    return (
      <Button
        {...props}
        ref={ref}
        appearance="transparent"
        icon={<IconOpenAdvancedSearch />}
        size="small"
        onClick={() => {
          setOpenAdvancedSearch(!openAdvancedSearch);
        }}
      />
    );
  });

  /**
   * styles
   ************************************************************************************
   */
  const styles = useStyles();

  const [fieldValue, setFieldValue] = React.useState(
    _.defaultTo(
      urlSuchParameter[dataDefinition.searchFields[0].urlParameter],
      ""
    )
  );

  /**
   * return
   ************************************************************************************
   */
  return (
    <React.Fragment>
      <Input
        ref={setTarget}
        contentBefore={
          <IconSearch
            color={
              !_.isEmpty(getQueryKeyObject(dataDefinition.searchFields))
                ? tokens.colorBrandForegroundOnLight
                : undefined
            }
          />
        }
        contentAfter={
          <Popover open={openAdvancedSearch} onOpenChange={handleOpenChange}>
            <PopoverTrigger>
              <AdvancedSearchButton aria-label="Open advanced search" />
            </PopoverTrigger>
            <PopoverSurface>
              <SearchFields
                dataDefinition={dataDefinition}
                changeData={setFieldValue}
              />
            </PopoverSurface>
          </Popover>
        }
        placeholder={"Suche"}
        className={styles.searchField}
        id="myInput"
        type="text"
        //  value={urlSuchParameter[dataDefinition.searchFields[0].urlParameter]}
        value={fieldValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue(e.target.value);
          debouncedSearch(
            dataDefinition.searchFields[0].urlParameter,
            e.target.value
          );
        }}
        defaultValue={_.defaultTo(
          urlSuchParameter[dataDefinition.searchFields[0].urlParameter],
          ""
        )}
      />
    </React.Fragment>
  );
};
