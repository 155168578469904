import React from "react";
import _ from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../store/store";
import { searchActions } from "../store/searchStore";
import { useSearchParams } from "react-router-dom";

import { Outlet } from "react-router-dom";
import { Header } from "./Header";
import { DialogContainer } from "./editDialog/DialogContainer";

import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

const useStyles = makeStyles({
  pageContainer: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "column",
    ...shorthands.overflow("hidden"),
  },

  pageItemContent: {
    height: "100%",
    ...shorthands.overflow("auto", "hidden"),
    ...shorthands.flex(1, 1, "auto"),
    ...shorthands.padding(tokens.spacingVerticalS),
  },
});

export const MembersOnly: React.FunctionComponent<any> = () => {
  const dispatch = useDispatch();

  let [searchParams, setSearchParams] = useSearchParams();
  const [urlIsRead, setUrlIsRead] = React.useState(false);

  const savedSuccessfully = useSelector(
    (state: IStore) => state.update.savedSuccessfully
  );

  /**
   * alle Parameter aus der URL werden in den Redux Store als Object als key-value-pair geschrieben
   */

  React.useEffect(() => {
    /**
     * Test für Objekte / Arrays
     */
    // const objekt: { [key: string]: string } = {};
    // objekt["hallo"] = "we";
    // objekt["poi"] = "aq";
    // objekt["huhu"] = "welt";
    // _.unset(objekt, "poi");
    // _.mapKeys(objekt, function (value, key) {
    //   console.log(key + ": " + value);
    // });
    /**
     * in den Store bringen
     */
    searchParams.forEach((key: string, value: string) => {
      if (!_.isEmpty(key) && !_.isEmpty(value)) {
        dispatch(
          searchActions.setUrlSuchParameter({ parameter: value, value: key })
        );
      }
    });
    setUrlIsRead(true);
  }, []);

  const styles = useStyles();

  return (
    <div className={styles.pageContainer}>
      <DialogContainer />
      <Header />
      <div className={styles.pageItemContent}>{urlIsRead && <Outlet />}</div>
    </div>
  );
};
