import React from "react";
import "./index.css";
import "@progress/kendo-theme-bootstrap/dist/all.css";
// import "../scss/kendo.scss";
// import "../scss/kendoLightTheme.scss";
import "./scss/kendoDarkTheme.scss";

import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store/store";
import App from "./components/App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
//import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root")!;
const root = createRoot(container);

const client = new QueryClient();
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={client}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
