import React from "react";

import { Text } from "@fluentui/react-components";

import {
  ArrowMoveInward20Filled as IconEreignis,
  Umbrella20Regular as IconSchaden,
  DocumentToolbox20Regular as IconVersichertePosition,
  BuildingHome20Regular as IconGebaeude,
  WindowWrench20Regular as IconBetriebseinrichtung,
  BoxMultiple20Regular as IconVorrat,
  ApprovalsApp20Regular as IconErtragsausfall,
  Money20Regular as IconKosten,
  BorderTopBottom20Regular as IconSonstige,
  BinFull20Regular as IconGewerk,
  Person20Regular as IconPerson,
  PeopleCommunity20Regular as IconFirma,
  HomeMore20Regular as IconAdresse,
  BuildingBank20Regular as IconBankverbindung,
  Mail20Regular as IconMail,
  Call20Regular as IconTelefonat,
  FolderOpen20Regular as IconOrdner,
  ClipboardTask20Regular as IconTodo,
  DocumentData20Regular as IconDatei,
  DocumentTextClock20Regular as IconChronologie,
  DocumentQuestionMark20Regular as IconInformationsanforderung,
  Payment20Regular as IconZahlung,
  Send20Regular as IconNachricht,
  PeopleAudience20Regular as IconBesprechung,
  CurrencyDollarEuro20Regular as IconGeldbetrag,
} from "@fluentui/react-icons";

import {
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  componentContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    ...shorthands.gap(tokens.spacingHorizontalS),
  },
  componentItem: {
    minWidth: "320px",
    ...shorthands.flex(0, 1, "400px"),
    boxShadow: tokens.shadow4,
  },

  columnContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    backgroundColor: tokens.colorNeutralBackground4,
  },

  page: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  table: {
    ...shorthands.flex(1, 1, "auto"),
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    ...shorthands.gap("10px"),
    width: "100%",
  },
  tableRow: {
    ...shorthands.flex(1, 1, "auto"),
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    height: "28px",
    alignItems: "center",
    justifyContent: "center",
  },
  rowSeparator: {
    ...shorthands.borderBottom("1px", "solid", tokens.colorBrandBackground),
  },
  column1: {
    ...shorthands.flex(0, 0, "40px"),
    justifyContent: "center",
    display: "flex",
  },
  column2: {
    ...shorthands.flex(0, 0, "40px"),
    display: "flex",
  },
  column3: {
    ...shorthands.flex(1, 1, "auto"),
    display: "flex",
  },
});

export const Legende: React.FunctionComponent = () => {
  const styles = useStyles();

  const objectTypeKontext: { Icon: any; abkuerzung: string; name: string }[] = [
    { Icon: IconEreignis, abkuerzung: "sne", name: "Schadenereignis" },
    { Icon: IconSchaden, abkuerzung: "sn", name: "Schaden" },
    {
      Icon: IconVersichertePosition,
      abkuerzung: "vp",
      name: "Versicherte Position",
    },
    {
      Icon: IconGebaeude,
      abkuerzung: "geb",
      name: "Gebäude, Raum, Gebäudeteil",
    },
    {
      Icon: IconBetriebseinrichtung,
      abkuerzung: "be",
      name: "Betriebseinrichtung",
    },
    {
      Icon: IconVorrat,
      abkuerzung: "vrt",
      name: "Vorrat",
    },
    {
      Icon: IconErtragsausfall,
      abkuerzung: "bu",
      name: "Ertragsausfall",
    },
    {
      Icon: IconKosten,
      abkuerzung: "kn",
      name: "Kosten",
    },
    {
      Icon: IconSonstige,
      abkuerzung: "son",
      name: "sonstiges",
    },
    {
      Icon: IconGewerk,
      abkuerzung: "gk",
      name: "Gewerk",
    },
  ];
  const objectTypeElement: { Icon: any; abkuerzung: string; name: string }[] = [
    { Icon: IconPerson, abkuerzung: "p", name: "Person (natürliche Person)" },
    { Icon: IconFirma, abkuerzung: "f", name: "Firma (juristische Person)" },
    { Icon: IconAdresse, abkuerzung: "adr", name: "Adresse" },
    { Icon: IconBankverbindung, abkuerzung: "bank", name: "Bankverbindung" },
    { Icon: IconMail, abkuerzung: "mail", name: "E-Mail" },
    { Icon: IconTelefonat, abkuerzung: "tel", name: "Telefonat" },
    { Icon: IconOrdner, abkuerzung: "ord", name: "Ordner" },
    { Icon: IconTodo, abkuerzung: "todo", name: "To-do" },
    { Icon: IconDatei, abkuerzung: "d", name: "Datei" },
    { Icon: IconChronologie, abkuerzung: "chr", name: "Chronologie" },
    {
      Icon: IconInformationsanforderung,
      abkuerzung: "ia",
      name: "Informationsanforderung",
    },
    {
      Icon: IconZahlung,
      abkuerzung: "zlg",
      name: "Zahlung",
    },
    {
      Icon: IconNachricht,
      abkuerzung: "nt",
      name: "Nachricht",
    },
    {
      Icon: IconBesprechung,
      abkuerzung: "bspr",
      name: "Besprechung",
    },
    {
      Icon: IconGeldbetrag,
      abkuerzung: "btg",
      name: "Geldbetrag",
    },
  ];
  return (
    <div className={styles.componentContainer}>
      <div
        className={mergeClasses(styles.componentItem, styles.columnContainer)}
      >
        <div className={styles.page}>
          <div className={styles.table}>
            {objectTypeKontext.map((entity, index) => {
              return (
                <div key={index} className={styles.tableRow}>
                  <div className={styles.column1}> {<entity.Icon />}</div>
                  <div className={styles.column2}>
                    {" "}
                    <Text>{entity.abkuerzung}</Text>
                  </div>
                  <div className={styles.column3}>
                    <Text>{entity.name}</Text>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        className={mergeClasses(styles.componentItem, styles.columnContainer)}
      >
        <div className={styles.page}>
          <div className={styles.table}>
            {objectTypeElement.map((entity, index) => {
              return (
                <div key={index} className={styles.tableRow}>
                  <div className={styles.column1}> {<entity.Icon />}</div>
                  <div className={styles.column2}>
                    {" "}
                    <Text>{entity.abkuerzung}</Text>
                  </div>
                  <div className={styles.column3}>
                    <Text>{entity.name}</Text>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Legende;
