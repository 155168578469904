import React, { useState } from "react";

import { useUserAuth } from "../services/useUserAuth";

import { IStore } from "../store/store";
import { useSelector, useDispatch } from "react-redux";

import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "../services/useTranslation";

import { Button, MenuItemProps } from "@fluentui/react-components";

import {
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuDivider,
  MenuGroup,
  MenuGroupHeader,
  MenuPopover,
  MenuItemRadio,
  MenuProps,
} from "@fluentui/react-components";

import {
  Home24Regular as IconHome,
  UmbrellaRegular as IconSchaden,
  Mail24Regular as IconMail,
  PersonArrowLeftRegular as IconLogout,
  LocalLanguageRegular as IconLanguage,
  CurrencyDollarEuroRegular as IconCurrency,
  AccessTimeRegular as IconTimeZone,
  GridDots24Regular as IconGrid,
  ChartMultipleRegular as IconChart,
  WeatherSunnyRegular as IconDesignLight,
  WeatherMoonFilled as IconDesignDark,
  DarkThemeRegular as IconDesignBrowser,
  QuestionCircleRegular as IconLegende,
} from "@fluentui/react-icons";

import {
  Text,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  pageItemHeader: {
    maxHeight: "32px",
    ...shorthands.flex(1, 1, "auto"),
    ...shorthands.padding(tokens.spacingVerticalS),
    ...shorthands.borderTop(
      tokens.strokeWidthThick,
      "solid",
      tokens.colorBrandBackground
    ),
    ...shorthands.borderBottom(
      tokens.strokeWidthThin,
      "solid",
      tokens.colorBrandBackground
    ),
  },
  headerContainer: {
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    alignContent: "flex-start",
    justifyContent: "center",
  },
  headerItemLeft: {
    ...shorthands.flex(0, 1, "auto"),
    ...shorthands.padding("0", tokens.spacingHorizontalL, "0", "0"),
    alignSelf: "flex-start",
  },
  headerItemNextToLeft: {
    ...shorthands.flex(1, 1, "auto"),
    ...shorthands.padding("4px", tokens.spacingHorizontalL, "0", "0"),
    height: "100%",
    alignSelf: "flex-start",
    display: "flex",
    flexWrap: "nowrap",
  },

  iconFlexItem: {
    ...shorthands.padding("0", tokens.spacingHorizontalS, "0", "0"),
    alignSelf: "flex-end",
  },
  textFlexItem: {
    ...shorthands.padding("0"),
    alignSelf: "center",
  },
});

export const HeaderMenu: React.FunctionComponent = () => {
  const styles = useStyles();
  const { userLogout } = useUserAuth();

  const navigate = useNavigate();
  const t = useTranslation();

  const location = useLocation();

  //get state from store
  const dispatch = useDispatch();

  const [isCalloutVisible, setCalloutVisible] = useState(false);

  const toggleCalloutVisible = (
    event: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    setCalloutVisible(!isCalloutVisible);
  };
  const languageCode = useSelector((state: IStore) => state.setting.language);

  const designIsDark = useSelector(
    (state: IStore) => state.setting.designIsDark
  );

  const ausloggen = () => {
    userLogout();
  };

  /***
   * Design
   ***/
  const design = useSelector((state: IStore) => state.setting.design);
  const language = useSelector((state: IStore) => state.setting.language);
  const currency = useSelector((state: IStore) => state.setting.currency);
  const timezone = useSelector((state: IStore) => state.setting.timezone);
  const onChangeDesignValue: MenuProps["onCheckedValueChange"] = () => {};

  const menuItemClickHandler = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    const menuItem: MenuItemProps = event.currentTarget;
    // const button: HTMLButtonElement = event.currentTarget;
    navigate("" + menuItem.id);
  };

  const MenuItemWrapper: React.JSXElementConstructor<{
    Icon: {
      (
        props: React.SVGAttributes<SVGElement> & {
          primaryFill?: string | undefined;
          className?: string | undefined;
          filled?: boolean | undefined;
          title?: string | undefined;
        }
      ): JSX.Element;
      displayName: string | undefined;
    };
    link: string;
    text: string;
  }> = ({ Icon, link, text }) => {
    return (
      <MenuItem
        // {...(this.props.editable ? {editable: this.props.editableOpts} : {})}
        icon={
          <Icon
            color={
              location.pathname === link
                ? tokens.colorBrandForegroundOnLight
                : undefined
            }
          />
        }
        id={link}
        onClick={menuItemClickHandler}
      >
        <Text weight={location.pathname === link ? "semibold" : undefined}>
          {text}
        </Text>
      </MenuItem>
    );
  };

  const DesignSubMenu = () => {
    return (
      <Menu
        onCheckedValueChange={onChangeDesignValue}
        checkedValues={{
          design: [design],
        }}
      >
        <MenuTrigger>
          <MenuItem icon={<IconDesignLight />}>Design</MenuItem>
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            <MenuGroup>
              <MenuItemRadio
                name="design"
                value="light"
                icon={<IconDesignLight />}
              >
                hell
              </MenuItemRadio>
              <MenuItemRadio
                name="design"
                value="dark"
                icon={<IconDesignDark />}
              >
                dunkel
              </MenuItemRadio>
              <MenuItemRadio
                name="design"
                value="browser"
                icon={<IconDesignBrowser />}
              >
                wie im Browser
              </MenuItemRadio>
            </MenuGroup>
          </MenuList>
        </MenuPopover>
      </Menu>
    );
  };

  const LanguageSubMenu = () => {
    return (
      <Menu
        onCheckedValueChange={onChangeDesignValue}
        checkedValues={{
          language: [language],
        }}
      >
        <MenuTrigger>
          <MenuItem icon={<IconLanguage />}>Sprache</MenuItem>
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            <MenuGroup>
              <MenuItemRadio name="language" value="de_DE">
                Deutsch
              </MenuItemRadio>
              <MenuItemRadio name="language" value="en_GB">
                English
              </MenuItemRadio>
              <MenuItemRadio name="language" value="it_IT">
                Italiano
              </MenuItemRadio>
            </MenuGroup>
          </MenuList>
        </MenuPopover>
      </Menu>
    );
  };

  const CurrencySubMenu = () => {
    return (
      <Menu
        onCheckedValueChange={onChangeDesignValue}
        checkedValues={{
          currency: [currency],
        }}
      >
        <MenuTrigger>
          <MenuItem icon={<IconCurrency />}>Währung</MenuItem>
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            <MenuGroup>
              <MenuItemRadio name="currency" value="EUR">
                Euro
              </MenuItemRadio>
              <MenuItemRadio name="currency" value="USD">
                Dollar
              </MenuItemRadio>
            </MenuGroup>
          </MenuList>
        </MenuPopover>
      </Menu>
    );
  };

  const TimezoneSubMenu = () => {
    return (
      <Menu
        onCheckedValueChange={onChangeDesignValue}
        checkedValues={{
          timezone: [timezone],
        }}
      >
        <MenuTrigger>
          <MenuItem icon={<IconTimeZone />}>Zeitzone</MenuItem>
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            <MenuGroup>
              <MenuItemRadio name="timezone" value="Europe/Berlin">
                Europe/Berlin (+1 UTC)
              </MenuItemRadio>
            </MenuGroup>
          </MenuList>
        </MenuPopover>
      </Menu>
    );
  };

  return (
    <Menu>
      <MenuTrigger>
        <Button size="medium" icon={<IconGrid />}></Button>
      </MenuTrigger>

      <MenuPopover>
        <MenuList>
          <MenuGroup>
            <MenuGroupHeader>Seiten</MenuGroupHeader>
            <MenuItemWrapper Icon={IconHome} link="/home" text="Startseite" />

            <MenuItemWrapper Icon={IconMail} link="/mail" text="E-Mail" />
            {/* <MenuItemWrapper
              Icon={IconEreignis}
              link="/schadenereignis"
              text="Schadenereignis"
            /> */}
            <MenuItemWrapper
              Icon={IconSchaden}
              link="/schaden"
              text="Schaden"
            />
            {/* <MenuItemWrapper Icon={IconChart} link="/charting" text="Chart" /> */}
            {/* <MenuItemWrapper
                  Icon={IconLayout}
                  link="/layout"
                  text="Layout"
                /> */}
            <MenuItemWrapper
              Icon={IconLegende}
              link="/legende"
              text="Legende"
            />
          </MenuGroup>
          <MenuDivider />
          <MenuGroup>
            <MenuGroupHeader>Ihre Einstellungen</MenuGroupHeader>
            <DesignSubMenu />
            <LanguageSubMenu />
            <CurrencySubMenu />
            <TimezoneSubMenu />
            <MenuDivider />
            <MenuItem icon={<IconLogout />} onClick={ausloggen}>
              ausloggen
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};
