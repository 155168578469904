import React from "react";
import _, { debounce } from "lodash";

import { IDataDefinition, ISearchField } from "./IDataDefinition";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { IStore } from "../../store/store";
import { searchActions } from "../../store/searchStore";

export const useSearchFunctions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const urlSuchParameter = useSelector(
    (state: IStore) => state.search.urlSuchParameter
  );
  let [searchParams, setSearchParams] = useSearchParams({});

  const urlParamEncode = (str: string) => {
    return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
      return "%" + c.charCodeAt(0).toString(16);
    });
  };

  const debouncedSearch = React.useRef(
    debounce(async (urlParameter: string, wert: string) => {
      /**
       * set internal store
       */
      dispatch(
        searchActions.setUrlSuchParameter({
          parameter: urlParameter,
          value: wert,
        })
      );
      /**
       * set browser url
       */
      searchParams.set(urlParameter, wert);
      setSearchParams(searchParams);

      /**
       * Zeit in ms, wie lange zwischen den Eingaben gewartet werden soll, um Abfrage abzufeuern
       */
    }, 500)
  ).current;

  React.useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  /**
   * API-Anfrage aufbauen
   ************************************************************************************
   */
  const getApiQueryString = (
    dataDefinition: IDataDefinition,
    pageNumber: number,
    pageLimit: number = 25
  ): string => {
    let apiQueryString =
      dataDefinition.readResourceCollection +
      "?limit=" +
      pageLimit +
      "&page=" +
      pageNumber;
    let whereFilterNummer = 0;

    dataDefinition.searchFields.forEach((searchField: ISearchField) => {
      const urlParameterValue = _.defaults(
        urlSuchParameter[searchField.urlParameter],
        ""
      );

      if (!_.isEmpty(urlParameterValue)) {
        apiQueryString =
          apiQueryString +
          "&filter[" +
          whereFilterNummer +
          "][alias]=" +
          searchField.databaseTable +
          "&filter[" +
          whereFilterNummer +
          "][field]=" +
          searchField.databaseField +
          "&filter[" +
          whereFilterNummer +
          "][type]=" +
          searchField.whereFilterType +
          "&filter[" +
          whereFilterNummer +
          "][value]=" +
          urlParamEncode(urlParameterValue);
        /**
         * Nummer hochzählen für nächsten Parameter
         */
        whereFilterNummer = whereFilterNummer + 1;
      }
    });
    return apiQueryString;
  };

  /**
   * das Objekt mit den Suchparametern für Collection-Abfragen zurückgeben
   ************************************************************************************
   */
  const getQueryKeyObject = (searchFields: ISearchField[]): {} => {
    let queryKeyObject: { [key: string]: string } = {};

    searchFields.forEach((searchField: ISearchField) => {
      const urlParameterValue = _.defaults(
        // searchParams.get(searchField.urlParameter),
        urlSuchParameter[searchField.urlParameter],
        ""
      );
      if (!_.isEmpty(urlParameterValue)) {
        queryKeyObject[searchField.urlParameter] = urlParameterValue;
      }
    });
    /**
     * @todo wird Objekt hier in der richtigen Syntax gebildet?
     */
    // console.log(queryKeyObject);
    return queryKeyObject;
  };

  return {
    debouncedSearch,
    getApiQueryString,
    getQueryKeyObject,
  };
};
