import { Config } from "../config/config";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const useReactQueryAxios = () => {
  const navigate = useNavigate();

  async function rpcFetchAll(resourceUrl: string) {
    try {
      const response = await axios({
        url: Config.apiUrl + resourceUrl,
        method: "get",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Accept: "application/json",
        },
        responseType: "json",
      });

      // const response = await axios.get(
      //   "https://api.github.com/repos/facebook/react/issues?page=1"
      // );

      //Logging in DEV
      process.env.NODE_ENV !== "production" &&
        console.log("inside useRpcFetchAll", {
          status: response.status,
          resourceUrl: resourceUrl,
          response: response,
        });

      if (resourceUrl.slice(0, 5) === "/mail") {
        return response.data.messages;
      }
      return response.data.collection;
    } catch (error: any) {
      //Logging in DEV
      process.env.NODE_ENV !== "production" &&
        console.log("error inside useRpcFetchAll", {
          status: error?.response?.status,
          resourceUrl: resourceUrl,
          response: error,
        });

      //nicht authentifiziert, zur LoginSeite
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      }

      return null;
    }
  }
  return { rpcFetchAll };
};
